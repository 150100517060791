/* eslint-disable mastery/no-fetch */
import { APP_HTTP_FETCH_SECURITY } from '@app/GlobalVariables/constants';
import { getGlobalVariable } from '@app/GlobalVariables/util';
import { getAuthHeader } from '@app/auth/token';
import { ENV_VARS } from '@env';
import {
  GEONAMES_ENDPOINT,
  IS_DEV_DOMAIN,
  IS_LOCAL_DEV,
  IS_LOCAL_OR_CI_CYPRESS,
  IS_PR_PREVIEW,
} from '@utils/constants';
import { reportCustomSentryError } from '@utils/sentry';
import { win } from '@utils/win';
import { config } from '../../config';

export const endpoints = Object.freeze({
  alchemy: config.alchemyDataEndpoint,
  caenLocking: config.caenLockingApiEndpoint,
  flatfile: config.flatFileApiEndPoint,
  geonames: GEONAMES_ENDPOINT,
  ltlPricingMatrix: config.ltlPricingMatrix,
  masterbid: config.masterbidEndpoint,
  minion: config.minionApiRestEndpoint,
  regions: config.regionsDataEndpoint,
  rest: config.restApiEndpoint,
  uploads: config.uploadsApiEndpoint,
  documentApi: config.documentApiEndpoint,
});

export type FetchEndpoints = keyof typeof endpoints;

interface FetchHttpArgs extends Partial<RequestInit> {
  endpoint: FetchEndpoints;
}

export const fetchHttp = (
  url: string,
  kwargs: Omit<FetchHttpArgs, 'headers'> & { headers?: Record<string, string> }
): Promise<Response> => {
  const flag = getGlobalVariable(APP_HTTP_FETCH_SECURITY);
  const { endpoint, headers, ...opts } = kwargs;
  const base = endpoints[endpoint ?? ''];
  if (flag) {
    if (!endpoint) {
      throw new Error(
        `No endpoint provided. Must use a valid known endpoint: ${Object.keys(
          endpoints
        ).join(', ')}`
      );
    }
    if (!base) {
      throw new Error(`No endpoint configured for ${endpoint}`);
    }
    if (!url.match(base)) {
      throw new Error(
        `Invalid url ${url} for endpoint ${endpoint}. Must match ${base}`
      );
    }
  }
  return fetch(url, {
    mode: 'cors',
    headers: new Headers(headers),
    ...opts,
  });
};

export const sendLockBeacon = (
  url: string,
  blob:
    | BlobPart
    | BufferSource
    | FormData
    | URLSearchParams
    | ReadableStream<Uint8Array>
    | string
    | null
    | undefined
): void => {
  const flag = getGlobalVariable(APP_HTTP_FETCH_SECURITY);
  if (flag) {
    if (!url.match(endpoints.caenLocking)) {
      throw new Error(
        `Invalid url ${url}. Must match ${endpoints.caenLocking}`
      );
    }
  }
  win.navigator.sendBeacon(url, blob);
};

/** This utility should NOT be used for anything other than experiments, as it will only fetch from dev no matter the environment it is run in. For typical graphql calls you should follow the guidelines here https://masterysys.atlassian.net/l/cp/jV4wB408  */
export const fetchFromGraphInternalDevOnly = (
  kwargs: Pick<FetchHttpArgs, 'body'> & {
    qs?: string;
  }
): Promise<Response> => {
  let url =
    ENV_VARS.VITE_EXPERIMENT_ENDPOINT ??
    'https://graphql.dev.mm100.mastermindtms.com';
  if (IS_LOCAL_OR_CI_CYPRESS) {
    url = config.apiEndpoint;
  }
  if (kwargs.qs) {
    url += `?${kwargs.qs.replace('?', '')}`;
  }
  if (
    !IS_DEV_DOMAIN &&
    !IS_LOCAL_DEV &&
    !IS_PR_PREVIEW &&
    !IS_LOCAL_OR_CI_CYPRESS
  ) {
    const err = new Error('Fetch utility used in incorrect environment.');
    reportCustomSentryError(err);
    throw err;
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: getAuthHeader(),
      'Content-Type': 'application/json',
    },
    body: kwargs.body,
  });
};
