import { RelativeString } from '@components/DateRangePicker/util/relative';
import { DatePickerVersion2 } from '@components/DatepickerV2';
import { IsInDialogContext } from '@components/Dialog/context';
import { AUTOCOMPLETE_OFF_VALUE } from '@components/Input';
import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { useViewOnly } from '@components/ViewOnly';
import { useFlagMe123288MigrateDatepickerToVersion2 } from '@generated/flags/ME-123288-migrate-datepicker-to-version-2';
import { useFlagMe214355SpectrumUpgradeDialog } from '@generated/flags/ME-214355-spectrum-upgrade-dialog';
import { useEffectAfterMount } from '@hooks/useEffectAfterMount';
import { parseAbsolute, parseZonedDateTime } from '@internationalized/date';
import { DateValue } from '@react-types/datepicker';
import { FullStoryElementType, FullStoryTypes } from '@utils/fullstory';
import {
  IANATimezones,
  currentTZ,
  dateFnsFormat,
  getDateMillis,
  parseStringDate,
  shiftDateByTimezone,
  strHasYear,
} from '@utils/time';
import { startOfDay } from '@utils/time/util';
import { isDate, isValid, subDays } from 'date-fns';
import { isString, omit } from 'lodash-es';
import {
  ReactElement,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { usePrevious } from 'react-use';
import { ReadOnlyField } from '../Field/ReadOnlyField';

const padTwoDigits = (n: number): string => n.toString().padStart(2, '0');

interface DeprecatedDatePickerProps {
  /** @deprecated This prop has no impact on DatePicker V2 */
  popperContainer?: ReactDatePickerProps['popperContainer'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  popperModifiers?: ReactDatePickerProps['popperModifiers'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  popperPlacement?: ReactDatePickerProps['popperPlacement'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  selectsStart?: ReactDatePickerProps['selectsStart'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  selectsEnd?: ReactDatePickerProps['selectsEnd'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  showPopperArrow?: ReactDatePickerProps['showPopperArrow'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  dateFormat?: ReactDatePickerProps['dateFormat'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  customInput?: ReactDatePickerProps['customInput'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  highlightDates?: ReactDatePickerProps['highlightDates'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  startDate?: ReactDatePickerProps['startDate'];
  /** @deprecated This prop has no impact on DatePicker V2 */
  endDate?: ReactDatePickerProps['endDate'];
  /** @deprecated This prop no longer forces using DatePickver V1 */
  forceV1?: boolean;
}

interface DatePickerV2BaseProps extends DeprecatedDatePickerProps {
  id?: string;
  readOnly?: boolean;
  value?: Date | null | string;
  name?: string;
  'aria-invalid'?: boolean;
  limitDateRange?: boolean;
  dateFormatting?: boolean; // keep for v2
  showOnlyYear?: boolean; // keep for v2
  timezone?: IANATimezones; // keep for v2
  selected?: Date | null; // keep v2
  ['data-testid']?: string;
  relative?: RelativeString | null;
  onBlur?: (event: anyOk) => void;
  onFocus?: (event: anyOk) => void;
  onOpenChange?: (isOpen: boolean) => void;
  defaultOpen?: boolean;
  nonClearable?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  required?: boolean;
  /** filterDate is mapped to isDateUnavailabled prop in DatePicker Version 2 */
  filterDate?: (date: Date | DateValue) => boolean;
  hideRelativeOptions?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  showYears?: boolean;
  /** @deprecated This prop will not be supported in v2. Use the current user's time (like 11:30a) and apply that to the day selected */
  applyCurrentTime?: boolean;
}

export interface PropsWithTruthyOnChange extends DatePickerV2BaseProps {
  onChange?: (d: Date, relative: RelativeString | null) => void;
  /** `nonClearable` : the datepicker must have a Date. If the user blurs the component without a valid date, it will be set to the beginning of today's date in the proper timezone. This prop should not be used by default. */
  nonClearable: boolean;
}

interface PropsWithNullishOnChange extends DatePickerV2BaseProps {
  onChange?: (d: Date | null, relative: RelativeString | null) => void;
  /** `nonClearable` : the datepicker must have a Date. If the user blurs the component without a valid date, it will be set to the beginning of today's date in the proper timezone. This prop should not be used by default. */
  nonClearable?: undefined;
}

// ts-unused-exports:disable-next-line
export type Props = {
  fsName?: string;
  fsParent?: string;
  fsType?: FullStoryTypes;
  fsElement?: FullStoryElementType;
} & (PropsWithTruthyOnChange | PropsWithNullishOnChange);

const allowNullish = (props: Props): props is PropsWithNullishOnChange => {
  return Boolean(props.nonClearable) === false;
};

const sixtyDaysAsMillis = 1000 * 60 * 60 * 24 * 60;

const fitBounds = (d: Date): Date => {
  const diff = d.valueOf() - Date.now();
  if (diff < 0 && Math.abs(diff) > sixtyDaysAsMillis) {
    // if the date is more than 60 days prior to today
    // we typically do not want dates in the past
    // so if we find that the year is incorrect (typically this year)
    // set it to the next year.
    // this is special for our particular application, but works
    // but...if there is a mismatch in leap year vs not, and the date is 02/29, this can return a strange result
    d.setFullYear(Number(d.getFullYear()) + 1);
  }
  return d;
};

const customParse = (rawStr: string): Date | undefined => {
  const d = parseStringDate(rawStr);
  if (!d) {
    return undefined;
  }
  if (!isValid(d)) {
    return new Date();
  } else if (strHasYear(rawStr)) {
    return d;
  }
  return fitBounds(d);
};

const coerceToTimezone = (
  date: Date | string,
  timezoneFrom: string,
  timezoneTo: string
): Date => {
  if (!timezoneFrom || !timezoneTo || timezoneFrom === timezoneTo) {
    return new Date(date);
  }
  return shiftDateByTimezone(new Date(date), timezoneFrom, timezoneTo);
};

const normalizeSelection = (
  rawDate: Date,
  applyCurrentTime?: boolean,
  tz?: string
): Date => {
  // As of writing, there is a bug with react-datepicker where keyboard selection differs from mouse selection, so we normalize below to startOfDay for consistency.
  // https://github.com/Hacker0x01/react-datepicker/issues/1484
  try {
    const dLocal = parseAbsolute(rawDate.toISOString(), currentTZ);
    const dObj = parseZonedDateTime(
      `${padTwoDigits(dLocal.year)}-${padTwoDigits(
        dLocal.month
      )}-${padTwoDigits(dLocal.day)}T${padTwoDigits(
        dLocal.hour
      )}:${padTwoDigits(dLocal.minute)}:${padTwoDigits(dLocal.second)}[${
        tz || currentTZ
      }]`
    );
    let val = startOfDay(dObj).toDate();
    if (applyCurrentTime) {
      const millis = getDateMillis(new Date());
      val = new Date(val.valueOf() + millis);
    }
    return val;
  } catch {
    return new Date();
  }
};

const CustomInput = forwardRef<HTMLInputElement, fixMe>((props, ref) => (
  <input
    data-testid="datepicker-input"
    type="text"
    {...props}
    ref={ref}
    onChange={(e): void => {
      const str = (e.target.value || '').trim();
      const replaced = str.replace(/(\D)/gi, '/');
      props.onChange?.({
        isDefaultPrevented: () => false,
        target: {
          value: replaced,
        },
      });
    }}
  />
));

export function DatePicker(props: Props): ReactElement {
  const {
    onChange,
    limitDateRange,
    minDate,
    dateFormatting,
    showOnlyYear,
    timezone,
    showYears,
    defaultOpen,
    applyCurrentTime,
    fsName,
    fsParent,
    fsType,
    fsElement,
  } = props;
  const { isViewOnly } = useViewOnly();
  const spectrumUpgradeDialog = useFlagMe214355SpectrumUpgradeDialog();
  const isInDialog = useContext(IsInDialogContext);
  const [userHasPermission, permissionScope] = useHasPermissionFromContext();
  const readOnly = !userHasPermission || props.readOnly;
  // `input` is a prop available on the ReactDatePicker component, but it's not
  // explicitly defined in the type declarations:
  const datePickerRef = useRef<ReactDatePicker & { input: HTMLInputElement }>(
    null
  );

  const [internalVal, setInternalValRaw] = useState<Date | null | undefined>(
    props.selected
  );

  const migrateToVersion2 = useFlagMe123288MigrateDatepickerToVersion2();

  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const setInternalVal = (
    val: Date | null,
    skipFit?: true,
    skipNormalize?: true
  ): void => {
    let d = val;
    if (isDate(val) && isValid(val) && d !== null && !skipFit) {
      d = fitBounds(d);
    }
    if (!skipNormalize) {
      d = d ? normalizeSelection(d, applyCurrentTime, timezone) : d;
    }
    setLastUpdated(() => Date.now());
    setInternalValRaw(() => d);
  };

  const resolvedTZ = timezone || currentTZ;

  const valOrSelection = isString(props.value)
    ? props.value
      ? new Date(props.value)
      : null
    : props.value || props.selected;

  const coercedSelection = coerceToTimezone(
    valOrSelection || new Date(),
    resolvedTZ,
    currentTZ
  );
  const dateFormat = showOnlyYear
    ? 'yyyy'
    : dateFormatting
    ? 'MM/dd/yy'
    : 'MM/dd';

  const prevTZ = usePrevious(timezone);

  useEffect(() => {
    if (prevTZ && prevTZ !== timezone && valOrSelection) {
      const newVal = coerceToTimezone(
        valOrSelection || new Date(),
        prevTZ,
        resolvedTZ
      );
      onChange?.(newVal, props.relative ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevTZ, timezone]);

  useEffectAfterMount(() => {
    if (valOrSelection && migrateToVersion2 === false) {
      setInternalValRaw(new Date(valOrSelection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valOrSelection?.valueOf()]);

  useEffectAfterMount(() => {
    if (migrateToVersion2) {
      setInternalValRaw(valOrSelection ? new Date(valOrSelection) : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valOrSelection?.valueOf()]);

  if (readOnly && coercedSelection) {
    return (
      <ReadOnlyField data-scope={permissionScope}>
        {valOrSelection ? dateFnsFormat(coercedSelection, dateFormat) : ''}
      </ReadOnlyField>
    );
  }

  if (isViewOnly && coercedSelection) {
    return (
      <div data-testid={props.id || props.name}>
        {valOrSelection ? dateFnsFormat(coercedSelection, dateFormat) : ''}
      </div>
    );
  }

  const internalOnChange = (): void => {
    if (props.onChange && allowNullish(props)) {
      props.onChange(internalVal ?? null, props.relative ?? null);
    } else if (onChange && internalVal) {
      onChange(internalVal, props.relative ?? null);
    }
  };
  const dialogConsideration =
    (isInDialog && spectrumUpgradeDialog) || !isInDialog;
  // the DialogV1 and DatepickerV2 are not compatiable, only use the new version of datepicker in a DialogV2
  if (migrateToVersion2 && dialogConsideration && !props?.forceV1) {
    return (
      <DatePickerVersion2
        fsName={fsName ?? props?.name}
        fsParent={fsParent}
        fsType={fsType}
        fsElement={fsElement}
        data-testid={props?.['data-testid'] ?? null}
        id={props?.id ?? null}
        name={props?.name ?? undefined}
        hideRelativeOptions={props?.hideRelativeOptions ?? true}
        aria-invalid={props['aria-invalid']}
        value={valOrSelection}
        onChange={({ value, relative: relativeOptionSelected }): void => {
          if (props?.onChange) {
            props.onChange?.(
              (value as Date) ?? null,
              relativeOptionSelected ?? null
            );
          }
        }}
        timezone={resolvedTZ}
        relative={props.relative || null}
        onBlur={(e): void => {
          if (props?.onBlur) {
            props?.onBlur(e);
          }
        }}
        onFocus={(e): void => {
          if (props?.onFocus) {
            props?.onFocus(e);
          }
        }}
        onOpenChange={(isOpen): void => {
          if (props?.onOpenChange) {
            props?.onOpenChange(isOpen);
          }
        }}
        showYears={showYears || dateFormatting ? true : false}
        readOnly={readOnly}
        defaultOpen={defaultOpen ?? false}
        minValue={
          isValid(props?.minDate) || limitDateRange
            ? props.minDate
            : limitDateRange
            ? minDate || subDays(new Date(), 60)
            : subDays(new Date(), 32850)
        }
        maxValue={isValid(props?.maxDate) ? props?.maxDate : undefined}
        isDisabled={props?.disabled ?? false}
        nonClearable={props?.nonClearable ?? false}
        autoFocus={props?.autoFocus ?? false}
        isRequired={props?.required ?? false}
        isDateUnavailable={(date: DateValue): boolean => {
          if (props?.filterDate) {
            return props.filterDate(date);
          }
          return false;
        }}
      />
    );
  }

  return (
    <ReactDatePicker
      ref={datePickerRef}
      autoComplete={AUTOCOMPLETE_OFF_VALUE}
      showPopperArrow={false}
      {...omit(props, ['dateFormatting', 'onChange'])}
      value={undefined}
      dateFormat={dateFormat}
      {...(valOrSelection &&
        timezone && {
          selected: coercedSelection,
        })}
      onChange={undefined as anyOk}
      onBlur={(): void => {
        internalOnChange();
      }}
      onCalendarClose={(): void => {
        internalOnChange();
      }}
      onChangeRaw={(event): void => {
        const { value } = event.target;
        if (!value) {
          setInternalVal(null);
          return;
        }
        const parsed = customParse(value);
        setInternalVal(parsed ?? null, true);
      }}
      onSelect={(d): void => {
        if (Date.now() - lastUpdated > 50) {
          setInternalVal(d, true);
        }
      }}
      customInput={
        <CustomInput
          data-testid={
            props['data-testid']
              ? `datepicker-input-${props['data-testid']}`
              : 'datepicker-input'
          }
          data-scope={permissionScope}
          type="text"
        />
      }
      minDate={
        props.minDate ||
        (limitDateRange
          ? minDate || subDays(new Date(), 60)
          : subDays(new Date(), 32850))
      }
    />
  );
}
